import React from 'react'
import PortableText from './portableText'
import GallerySlider from './gallery-slider'

class TeamMember extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOverlaySliderOpen: false,
      isOverlaySliderVisible: true,
      slideIndex: 0,
    }
  }

  render() {
    const { title, bioTitle, _rawBio, _rawGallerySlides, links } = this.props
    const slides = _rawGallerySlides

    return (
      <div className="page-container">
        <div className="team-member">
          <div className="team-member__header">
            {title && <h1 className="team-member__title">{title}</h1>}
          </div>
          {_rawBio && (
            <>
              <div className="btn btn--info btn--header artist__text-title">
                {bioTitle ? bioTitle : 'Bio'}
              </div>
              <div className="team-member__bio">
                <div className="team-member__bio-inner">
                  {_rawBio && <PortableText blocks={_rawBio} />}
                </div>
              </div>
            </>
          )}
          {slides && (
            <GallerySlider
              type="teamMember"
              isTeamMember
              slides={slides}
              slideIndex={this.state.slideIndex}
              setSlideIndex={this.setSlideIndex}
              toggleOverlaySlider={this.toggleOverlaySlider}
              noOverlay
            />
          )}
          {links && (
            <div className="team-member__links">
              {links.map(link => (
                <a
                  className="btn"
                  href={link.download ? link.download.asset.url : link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.title}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default TeamMember
