import React from 'react'
import { graphql } from 'gatsby'
import ContentContainer from '../containers/content'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import TeamMember from '../components/team-member'

export const query = graphql`
  query TeamMemberTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    teamMember: sanityTeamMember(id: { eq: $id }) {
      id
      title
      bioTitle
      links {
        title
        href
        download {
          asset {
            url
          }
        }
      }
      _rawBio(resolveReferences: { maxDepth: 5 })
      _rawGallerySlides(resolveReferences: { maxDepth: 5 })
    }
  }
`

const TeamMemberTemplate = props => {
  const { data, errors } = props
  const site = (data || {}).site
  const siteOgImage = site.openGraph.image
  const teamMember = data && data.teamMember

  return (
    <ContentContainer section="manifesto" singleView>
      {errors && <SEO title="GraphQL Error" />}
      {teamMember && (
        <SEO
          title={teamMember.title || site.title}
          description={teamMember.metaDescription || site.description}
          keywords={site.keywords}
          image={siteOgImage}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {teamMember && <TeamMember {...teamMember} />}
    </ContentContainer>
  )
}

export default TeamMemberTemplate
